import React, { useEffect } from "react";
import Button from "../Button";

export default function Alert({ message, onClose }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-black/60">
      <div className="flex items-center justify-center h-full">
        <div className="relative w-full mx-3 bg-[#101115] md:w-[500px] border border-white rounded-[28px]">
          <div className="px-5 sm:px-[76px] py-[50px] flex flex-col justify-center items-center">
            <p className="font-normal text-[18px] text-center text-white/80 mb-5">
              {message}
            </p>

            <Button text={"OK"} onClick={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
}
