import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Button from "../components/common/Button";
import { API } from "../config/api";
import Alert from "../components/common/popup/Alert";
import { isLoggedIn, getUserData } from "../utils/userData";

export default function Verfication({ location }) {
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(location.state?.session);
  const [error, setError] = useState(null);
  const [reSending, setReSending] = useState(false);
  const [code, setCode] = useState("");
  const [checkAuth, setCheckAuth] = useState(true);
  const [alert, setAlert] = useState("");

  useEffect(() => {
    if (isLoggedIn() && getUserData()) {
      navigate("/");
    } else {
      setCheckAuth(false);
    }
  }, []);

  const handleCode = async () => {
    if (!code) {
      setError("Missing 6 digit code");
    } else {
      setLoading(true);
      setError(null);
      const data = {
        code: code,
        email: location.state?.data.email,
        sessionhash: session,
      };

      try {
        await API.post("/verify-code", data);
        setSession("");
        setAlert("You've successfully Signed Up");
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data) {
          setError(err.response.data?.error);
        }
      }
    }
  };

  const reSendCode = async () => {
    setReSending(true);
    try {
      const res = await API.post("/signup", location.state?.data);
      setAlert("A new code has been sent to your email");
      setSession(res.data?.sessionhash);
      setReSending(false);
    } catch (err) {
      setReSending(false);
      if (err?.response?.data) {
        alert(err.response.data?.error);
      }
    }
  };

  const closePopup = () => {
    setAlert("");
    if (!session) {
      navigate("/signin");
    }
  };

  if (checkAuth) return <div></div>;

  return (
    <Layout>
      <div className="mb-[200px] relative">
        <div className="absolute hidden md:block top-0 right-0 bottom-0 left-0 -z-10 min-h-[313px] wave">
          <StaticImage
            src="../images/hero.webp"
            alt="contact-us"
            placeholder="blurred"
          />
        </div>
        <div className="max-w-[1040px] mx-5 xl:mx-auto border pb-[70px] pt-[52px] pl-5 pr-5 sm:pl-[68px] sm:pr-[68px] md:pr-0 mt-[26px] bg-[#16171B] border-white/30 rounded-[28px]">
          <div className="flex flex-col md:flex-row">
            <div className="w-full">
              <StaticImage
                src="../images/logo.png"
                alt="logo"
                placeholder="blurred"
                height={54}
              />
              <p className="mt-[32px] text-[20px] leading-[30px] font-bold tracking-[0.135em] text-white">
                {/* Verifiy your Mobile Number */}
                Verify your email address
              </p>
              <p className="mt-[5px] text-sm leading-[21px] font-bold tracking-[0.135em] text-white/30 mb-5">
                Thank you for signing up. A verification code has been sent to
                your email. If you did not recieve it, please check your
                spam/junk folder. Otherwise <u>Get new code</u>
              </p>
              <p className="mt-[5px] text-sm leading-[21px] font-bold tracking-[0.135em] text-white/30 w-[300px] sm:w-[344px]">
                {/* Enter the 6-Digit verification code sent to your mobile device. */}
                Enter the 6-Digit verification code sent to your mail.
              </p>
              {error !== null && (
                <span className="text-red-700 opacity-80 -mb-5   block font-bold text-sm">
                  {error}
                </span>
              )}
              <div className="mt-[22px]">
                <div className="flex flex-col sm:flex-row sm:items-center">
                  <input
                    className="flex items-center outline-none justify-center text-[13px] px-[26px] leading-[20px] font-bold tracking-[0.135em] text-white w-[318px] h-[50px] bg-alto/5 rounded-[25px]"
                    value={location?.state?.data.email}
                    type={"text"}
                    readOnly
                  />
                  <span className="block txt-gradient-yellow text-sm leading-[21px] font-bold tracking-[0.135em] cursor-pointer mt-2 sm:mt-0 ml-[22px]">
                    <button onClick={reSendCode} disabled={reSending}>
                      {reSending ? "Sending..." : "Get new code"}
                    </button>
                  </span>
                </div>

                <div className="flex items-center mt-[9px] mb-5 text-[13px] px-[26px] leading-[20px] font-bold tracking-[0.135em] text-white w-[318px] h-[50px] bg-alto/5 rounded-[25px]">
                  <div className="flex items-center justify-center">G -</div>
                  <input
                    className="bg-transparent ml-5 tracking-[0.135em] w-4/5 outline-none border-none"
                    type={"text"}
                    placeholder="Enter 6-Digit Code"
                    value={code}
                    maxLength={6}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>

                <Button
                  text={loading ? "LOADING..." : "VERIFY"}
                  disable={loading}
                  width="w-[170px]"
                  onClick={handleCode}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {alert !== "" && <Alert message={alert} onClose={closePopup} />}
    </Layout>
  );
}
